


















































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import StateMixin from '@/mixins/state'
import FileEditor from './FileEditor.vue'

@Component({
  components: {
    FileEditor
  }
})
export default class FileEditorDialog extends Mixins(StateMixin) {
  @Prop({ type: Boolean, required: true })
  public value!: boolean

  @Prop({ type: String, required: true })
  root!: string

  @Prop({ type: String, required: true })
  public filename!: string;

  @Prop({ type: String, required: true })
  public contents!: string

  @Prop({ type: Boolean, default: false })
  public loading!: boolean

  @Prop({ type: Boolean, default: false })
  public readonly!: boolean

  updatedContent = this.contents
  lastSavedContent = this.updatedContent
  editorReady = false
  shortcutsDialog = false

  get ready () {
    return (
      !this.loading &&
      this.editorReady &&
      !this.isUploading
    )
  }

  get isUploading (): boolean {
    return this.$store.state.files.uploads.length > 0
  }

  get rootProperties () {
    return this.$store.getters['files/getRootProperties'](this.root)
  }

  get configMap () {
    return this.$store.getters['server/getConfigMapByFilename'](this.filename)
  }

  // get configRefUrl () {
  //   if (this.filename && this.filename.includes('moonraker.conf')) {
  //     return Globals.DOCS_MOONRAKER_CONFIG_REF
  //   } else {
  //     return Globals.DOCS_KLIPPER_CONFIG_REF
  //   }
  // }

  mounted () {
    this.updatedContent = this.contents
    this.lastSavedContent = this.updatedContent
  }

  async emitClose () {
    const confirmDirtyEditorClose = this.$store.state.config.uiSettings.general.confirmDirtyEditorClose
    if (confirmDirtyEditorClose && this.updatedContent !== this.lastSavedContent) {
      const result = await this.$confirm(
        this.$tc('app.general.simple_form.msg.unsaved_changes'),
        { title: this.$tc('app.general.label.unsaved_changes'), color: 'card-heading', icon: '$error' }
      )

      if (!result) {
        return
      }
    }

    this.$emit('input', false)
  }

  emitSave (restart: boolean) {
    if (this.editorReady) {
      if (this.configMap.serviceSupported && restart) {
        this.$emit('save', this.updatedContent, this.configMap.service)
        this.$emit('input', false)
      } else {
        this.$emit('save', this.updatedContent)
      }

      this.lastSavedContent = this.updatedContent
    }
  }

  handleKeyboardShortcuts () {
    this.shortcutsDialog = true
  }
}
